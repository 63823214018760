var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기본정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            label: "TBM번호",
                            name: "tbmNo",
                          },
                          model: {
                            value: _vm.tbmData.tbmNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmNo", $$v)
                            },
                            expression: "tbmData.tbmNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            afterIcon:
                              _vm.editable && !_vm.disabled
                                ? [
                                    {
                                      name: "search",
                                      click: true,
                                      callbackName: "searchWorkPermit",
                                    },
                                    {
                                      name: "close",
                                      click: true,
                                      callbackName: "removeWorkPermit",
                                    },
                                  ]
                                : null,
                            editable: _vm.editable,
                            readonly: true,
                            label: "작업허가번호",
                            name: "permitNo",
                          },
                          on: {
                            searchWorkPermit: _vm.searchWorkPermit,
                            removeWorkPermit: _vm.removeWorkPermit,
                          },
                          model: {
                            value: _vm.tbmData.permitNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "permitNo", $$v)
                            },
                            expression: "tbmData.permitNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "TBM명",
                            name: "tbmName",
                          },
                          model: {
                            value: _vm.tbmData.tbmName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmName", $$v)
                            },
                            expression: "tbmData.tbmName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업일",
                            type: "date",
                            name: "tbmWorkDate",
                          },
                          model: {
                            value: _vm.tbmData.tbmWorkDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmWorkDate", $$v)
                            },
                            expression: "tbmData.tbmWorkDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            haveProcess: true,
                            label: "업체",
                            name: "vendorCd",
                          },
                          model: {
                            value: _vm.tbmData.vendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "vendorCd", $$v)
                            },
                            expression: "tbmData.vendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-process", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "공정",
                            name: "processCd",
                          },
                          model: {
                            value: _vm.tbmData.processCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "processCd", $$v)
                            },
                            expression: "tbmData.processCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-construnction", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            name: "sopConstructionId",
                          },
                          model: {
                            value: _vm.tbmData.sopConstructionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "sopConstructionId", $$v)
                            },
                            expression: "tbmData.sopConstructionId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "작업",
                            name: "sopName",
                          },
                          model: {
                            value: _vm.tbmData.sopName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "sopName", $$v)
                            },
                            expression: "tbmData.sopName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업책임자",
                            name: "tbmWorkResponsibleId",
                          },
                          model: {
                            value: _vm.tbmData.tbmWorkResponsibleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmWorkResponsibleId", $$v)
                            },
                            expression: "tbmData.tbmWorkResponsibleId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.tbmData.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "plantCd", $$v)
                            },
                            expression: "tbmData.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            isArray: false,
                            codeGroupCd: "TBM_PRE_INSPECTION_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "사전점검",
                            name: "preInspections",
                          },
                          model: {
                            value: _vm.tbmData.preInspections,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "preInspections", $$v)
                            },
                            expression: "tbmData.preInspections",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업내용",
                            name: "tbmWorkContents",
                          },
                          model: {
                            value: _vm.tbmData.tbmWorkContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmWorkContents", $$v)
                            },
                            expression: "tbmData.tbmWorkContents",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "특이사항",
                            name: "significant",
                          },
                          model: {
                            value: _vm.tbmData.significant,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "significant", $$v)
                            },
                            expression: "tbmData.significant",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
            [
              _c(
                "c-table",
                {
                  ref: "riskTable",
                  attrs: {
                    title: "위험요인 목록",
                    columns: _vm.riskGrid.columns,
                    gridHeight: _vm.riskGrid.height,
                    data: _vm.tbmData.tbmRiskHazardList,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "위험요인을 추가하세요.",
                    hideBottom: true,
                    isTitle: true,
                    gridHeightAuto: true,
                    editable: _vm.editable && !_vm.disabled,
                    rowKey: "tbmRiskId",
                    selection: "multiple",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "추가",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addRisk },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.tbmData.tbmRiskHazardList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "제외",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeRisk },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
            [
              _c(
                "c-table",
                {
                  ref: "equipTable",
                  attrs: {
                    title: "장비현황 목록",
                    columns: _vm.equipGrid.columns,
                    gridHeight: _vm.equipGrid.height,
                    data: _vm.tbmData.tbmEquipList,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "장비현황을 추가하세요.",
                    hideBottom: true,
                    isTitle: true,
                    gridHeightAuto: true,
                    editable: _vm.editable && !_vm.disabled,
                    rowKey: "tbmEquipId",
                    selection: "multiple",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "추가",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addEquip },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.tbmData.tbmEquipList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "제외",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeEquip },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
            [
              _c(
                "c-table",
                {
                  ref: "attendeeTable",
                  attrs: {
                    title: "참석자 목록",
                    columns: _vm.attendeeGrid.columns,
                    gridHeight: _vm.attendeeGrid.height,
                    data: _vm.tbmData.tbmAttendeeList,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "참석자가 없습니다.",
                    hideBottom: true,
                    isTitle: true,
                    gridHeightAuto: true,
                    editable: _vm.editable && !_vm.disabled,
                    selection: "multiple",
                    rowKey: "tbmAttendeeId",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customArea",
                      fn: function ({ props, col }) {
                        return [
                          col.name === "click" &&
                          props.row["tbmDirectFlag"] !== "Y"
                            ? [
                                _c(
                                  "q-btn",
                                  {
                                    attrs: {
                                      round: "",
                                      unelevated: "",
                                      size: "xs",
                                      color: "amber",
                                      icon: "mode",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return (() => {
                                          ;(_vm.rowIndex = props.rowIndex),
                                            (_vm.electronSignature =
                                              props.row.electronSignature)
                                        }).apply(null, arguments)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "q-popup-proxy",
                                      {
                                        ref: "proxy_" + props.rowIndex,
                                        attrs: { breakpoint: 400 },
                                      },
                                      [
                                        _c(col.component, {
                                          tag: "component",
                                          attrs: {
                                            outFlag: true,
                                            popupParam: props.row,
                                            data: _vm.tbmData,
                                            rowIndex: props.rowIndex,
                                          },
                                          on: { callback: _vm.callback },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "업체",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addAttendee1 },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "현업",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addAttendee2 },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.tbmData.tbmAttendeeList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeAttendee },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("c-upload", {
                attrs: {
                  attachInfo: _vm.attachInfo,
                  editable: _vm.editable && !_vm.disabled,
                  label: "교육자료",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editable && !_vm.popupParam.tbmId,
                      expression: "editable && !popupParam.tbmId",
                    },
                  ],
                  attrs: { flat: true, label: "불러오기", icon: "save_alt" },
                  on: { btnClicked: _vm.copyTbm },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.editable && _vm.popupParam.tbmId && !_vm.disabled,
                      expression: "editable && popupParam.tbmId && !disabled",
                    },
                  ],
                  attrs: {
                    flat: true,
                    label: "삭제",
                    editable: _vm.editable,
                    icon: "delete_forever",
                  },
                  on: { btnClicked: _vm.remove },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editable && !_vm.disabled,
                      expression: "editable && !disabled",
                    },
                  ],
                  attrs: {
                    flat: true,
                    url: _vm.saveUrl,
                    isSubmit: _vm.isSave,
                    param: _vm.tbmData,
                    mappingType: _vm.saveType,
                    label: "저장",
                    icon: "save",
                  },
                  on: {
                    beforeAction: _vm.saveTbm,
                    btnCallback: _vm.saveCallback,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }